import React from 'react';
import { message, Modal } from 'antd';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import InfoList from './components/InfoList';
import './index.less';

const Pagination = Loader.loadBaseComponent('Pagination');
const TwoColumnLayout = Loader.loadBaseComponent('TwoColumnLayout');
const InfoFilter = Loader.loadBusinessComponent('InformationEntry', 'InfoFilter');
const InfoConfirm = Loader.loadBusinessComponent('InformationEntry', 'InfoConfirm');
const peopleOption = [
  { value: -1, label: '全部上传状态', icon: 'icon-S_Bar_List' },
  { value: 0, label: '上传成功', color: 'green' },
  { value: 1, label: '暂无照片', color: 'yellow' },
  { value: 2, label: '照片无特征值', color: 'blue' },
  { value: 3, label: '信息输入错误', color: 'red' },
  { value: 4, label: '其他', color: 'yard' }
];
@withRouter
@Decorator.businessProvider('tab')
@observer
class PeopleEntry extends React.Component {
  constructor(props) {
    super(props);
    const { history } = props;
    const { id, villageName } = Utils.queryFormat(history.location.search);
    this.state = {
      choseList: [],
      total: 0,
      peopleList: [],
      visible: false,
      modalType: 0,
      modalValue: {}, // 导入信息
      villageId: id, //选中的社区Id
      loading: false,
      villageName: decodeURI(villageName),
      modalInfo: undefined,
      searchData: {
        villageId: id,
        importStatus: -1,
        page: 1,
        pageSize: 10
      }
    };
    SocketEmitter.on(SocketEmitter.eventName.updateCommunityPeople, this.getVillagePeopleList);
  }

  componentDidMount() {
    this.getVillagePeopleList();
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updateCommunityPeople, this.getVillagePeopleList);
  }

  getVillagePeopleList = ops => {
    let { searchData, villageId } = this.state;
    searchData = { ...searchData, ...ops };
    let options = {
      importStatus: searchData.importStatus,
      villageId: villageId,
      offset: (searchData.page - 1) * searchData.pageSize,
      limit: searchData.pageSize
    };
    Service.person.listCommunityPersons(options).then(res => {
      this.setState({
        searchData,
        total: res.data.total,
        peopleList: res.data.list
      });
    });
  };

  onChange = (currentPage, pageSize) => {
    let { searchData } = this.state;
    searchData.page = currentPage;
    searchData.pageSize = pageSize;
    this.getVillagePeopleList(searchData);
  };

  ModalShow = () => {
    let { choseList, modalType } = this.state;
    if (choseList.length === 0 && modalType === 0) {
      message.info('请选择您要删除的人员信息');
      return;
    }
    this.setState({
      modalType: 0,
      visible: !this.state.visible
    });
  };

  deleteCommunityPeople = () => {
    let { choseList, villageId, modalType } = this.state;
    if (choseList.length === 0 && modalType === 0) {
      message.info('请选择您要删除的人员信息');
      return;
    }
    if (modalType === 0) {
      this.setState({
        loading: true
      });
      let option = {
        villageId,
        failSavedPeopleIds: [],
        successSavedPeopleIds: []
      };
      choseList.map(item => {
        if (item.importStatus === 1) {
          option.successSavedPeopleIds.push(item.id);
        } else {
          option.failSavedPeopleIds.push(item.id);
        }
      });
      Service.person.deletePersons(option).then(res => {
        this.setState(
          {
            visible: false,
            loading: false,
            choseList: []
          },
          () => {
            this.getVillagePeopleList({ page: 1 });
          }
        );
      });
    } else {
      this.setState(
        {
          visible: false
        },
        () => {
          this.getVillagePeopleList({ page: 1 });
        }
      );
    }
  };

  uploadZip = (fileUrl, fileName) => {
    let { villageId, villageName } = this.state;
    Service.person.importPersons({ villageId, villageName, fileName, fileUrl }).then(res => {
      // const modalInfo = Modal.info({ title: res.message });
      message.info(res.message);
      // this.modalInfoDestroy(modalInfo);
    });
  };

  crossValue = selectedRowKeys => {
    this.setState({
      choseList: selectedRowKeys
    });
  };

  modalInfoDestroy = modalInfo => {
    this.setState({
      modalInfo
    });
  };

  editPeople = parms => {
    if (!parms.manualImportFlag) {
      message.info('第三方导入的人员信息暂不支持编辑');
      return;
    }
    const { tab, location } = this.props;
    const id = Utils.uuid();
    LM_DB.add('parameter', {
      id,
      data: parms
    }).then(() => {
      tab.goPage({
        moduleName: 'peopleEdit',
        location,
        data: { id }
      });
    });
  };

  render() {
    let { total, searchData, villageId, peopleList = [], visible, choseList, modalType, villageName, loading, modalValue = {} } = this.state;
    return (
      <TwoColumnLayout title={`${villageName}人员信息`}>
        <div className="people-entry-view">
          <InfoFilter
            type="people"
            search={this.getVillagePeopleList}
            choseList={choseList}
            villageId={villageId}
            uploadZip={this.uploadZip}
            searchData={searchData}
            uploadType="lyy"
            villageName={villageName}
            deletePeople={this.ModalShow}
            optionList={peopleOption}
          />
          <div className="content-list">
            <InfoList list={peopleList} editPeople={this.editPeople} crossValue={this.crossValue} />
            <Pagination
              total={total}
              onChange={this.onChange}
              onShowSizeChange={this.onChange}
              current={searchData.page}
              pageSize={searchData.pageSize}
              pageSizeOptions={['10', '20', '30', '40', '50']}
            />
          </div>
        </div>
        <InfoConfirm type={modalType} data={modalValue} onOk={this.deleteCommunityPeople} visible={visible} loading={loading} messType="people" onCancel={this.ModalShow} />
      </TwoColumnLayout>
    );
  }
}

export default PeopleEntry;
